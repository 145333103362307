<template>
  <div class="Youtube">
    <v-dialog v-model="showDialog" :max-width="dialogWidth"
              :content-class="$vuetify.breakpoint.smAndDown ? 'dialog-top': ''">
      <v-card style="border-radius:16px">
        <v-card-text class="ma-0 pa-0">
          <v-responsive aspect-ratio="16/9" class="video-container">
            <iframe
                src="https://www.youtube.com/embed/b95CsbR5R3s"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                class="video-iframe"
                :style="$vuetify.breakpoint.smAndDown ? 'width: 100%; height: 220px; object-fit: cover;' : 'width: 100%; height: 252px; object-fit: cover;'"
            ></iframe>
          </v-responsive>
        </v-card-text>
        <v-card-actions class="ma-0 pa-0">
          <v-row>
            <v-col cols="6">
              <v-btn block color="white text--black" @click="closeForever" flat plain tile elevation="0">
                오늘 그만 보기
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn block color="white" @click="showDialog = false" flat plain tile elevation="0">
                닫기
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
    export default {
      name: 'Youtube',
      data() {
        return {
          showDialog: false,
          dialogWidth: "440px",
        };
      },
      created() {
        this.checkPopupStatus();
        window.addEventListener("resize", this.updateDialogWidth);
        this.updateDialogWidth();
      },
      beforeDestroy() {
        window.removeEventListener("resize", this.updateDialogWidth);
      },
      methods: {
        updateDialogWidth() {
          this.dialogWidth = window.innerWidth < 440 ? `${window.innerWidth - 40}px` : "440px";
          console.log(this.dialogWidth);
        },
        checkPopupStatus() {
          const today = new Date().toISOString().split("T")[0];
          const lastClosedDate = localStorage.getItem("popupClosedDate");

          if (lastClosedDate !== today) {
            this.showDialog = true;
          }
        },
        closeForever() {
          const today = new Date().toISOString().split("T")[0]; // 오늘 날짜 (YYYY-MM-DD 형식)
          localStorage.setItem("popupClosedDate", today); // 오늘 날짜를 저장
          this.showDialog = false; // 팝업 닫기
        },
      }
    };
</script>

<style scoped>
  ::v-deep .dialog-top {
    position: absolute;
    top: 0;
    margin: 54px 20px 0 20px;
  }

  ::v-deep .v-dialog {
    border-radius: 16px;
  }

  .video-container {
    overflow: hidden;
    border-radius: 16px 16px 0 0;
  }

  .video-iframe {
    width: 100%;
    height: 100%;
    border-radius: 16px 16px 0 0;
  }
</style>