<template>
  <div class="landing">
    <Main />
    <!-- <InstaOpen/> -->
    <!-- <TabContainer/> -->
    <References />
    <Example />
    <InterviewReview />
    <Row1 />
    <Row2 />
    <Row3 />
    <InspectionReview />
    <Row4 />
    <Price />
    <!-- <Promotion/> -->
    <!-- <Review /> -->
    <!--<Contacts/>-->
    <Downloads />
    <!-- <Introduce/> -->
    <Youtube />
  </div>
</template>

<script>
import Main from '@/components/landing/Landing';
// import TabContainer from '@/components/landing/TabContainer';
// import Review from "@/components/landing/Reviews";
// import Contacts from '@/components/landing/Contacts';
// import Introduce from '@/components/landing/Introduce';
import Row1 from '@/components/landing/rows/Row1';
import Row2 from '@/components/landing/rows/Row2';
import Row3 from '@/components/landing/rows/Row3';
import Row4 from '@/components/landing/rows/Row4';
import Price from '@/components/landing/Price';
// import Promotion from '@/components/landing/Promotion'
import Downloads from '@/components/landing/Downloads';
import References from '@/components/landing/References';
import Example from '@/components/landing/Example';
import InterviewReview from '../../components/landing/InterviewReview.vue';
import InspectionReview from '../../components/landing/InspectionReview.vue';
import Youtube from '@/components/landing/Youtube';

export default {
  name: 'Landing',
  components: {
    // InstaOpen,
    Main,
    // TabContainer,
    // Review,
    // Contacts,
    // Introduce,
    References,
    Row1,
    Row2,
    Row3,
    Row4,
    Price,
    // Promotion,
    Example,
    Downloads,
    InterviewReview,
    InspectionReview,
    Youtube
  },
};
</script>

<style scoped lang="scss">
* {
  font-family: 'Pretendard', 'Sans-serif';
}

::v-deep button {
  width: 220px;
  height: 48px;
  background-color: #000000;
  background-color: var(--default-color);
  color: #ffffff;
  color: var(--white-two-color);
  cursor: pointer;
  outlined: none;
  border: none;
  &.primary {
    background-color: #b00016;
    background-color: var(--primary-color);
  }
}

.landing {
  font-size: 15px;
  font-weight: 400;
  // letter-spacing: -1px;
  background-color: #ffffff;
  background-color: var(--white-two-color);
  max-width: 100vw;
  overflow-x: hidden;

  ::v-deep h1,
  .h1 {
    font-size: 40px !important;
  }

  ::v-deep h2,
  .h2 {
    font-size: 32px !important;
  }

  ::v-deep h3,
  .h3 {
    font-size: 27px !important;
  }

  ::v-deep h4,
  .h4 {
    font-size: 15px !important;
  }

  ::v-deep h5,
  .h5 {
    font-size: 14px !important;
  }
}

@media (max-width: 960px) {
  .landing {
    font-size: 14px;

    ::v-deep h1,
    .h1 {
      font-size: 29px !important;
    }
    ::v-deep h2,
    .h2 {
      font-size: 24px !important;
    }
    ::v-deep h3,
    .h3 {
      font-size: 18px !important;
    }
    ::v-deep h4,
    .h4 {
      font-size: 14px !important;
    }
    ::v-deep h5,
    .h5 {
      font-size: 13px !important;
    }
  }
}
</style>
